import {
  Alignment,
  BidirectionalAlignment,
} from '../components/ChallengesPage/Settings/challengeSettings/challengeSettings.types';

const rtl = {
  left: 'end',
  center: 'center',
  right: 'start',
};

const ltr = {
  left: 'start',
  center: 'center',
  right: 'end',
};

export const getBidirectionalAlignment = (
  alignment: Alignment,
  isRTL: boolean,
): Alignment => {
  if (isRTL === undefined) {
    return alignment;
  }

  if (isRTL) {
    return rtl[alignment] as BidirectionalAlignment;
  }
  return ltr[alignment] as BidirectionalAlignment;
};
